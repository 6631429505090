import typeOf from 'just-typeof'

/**
 * Safely outputs a time string in the right format of hh:MM
 * @param {string} [v=''] time value
 * @returns {string}
 */
export function formatToTime(v = '') {
  const value =
    typeOf(v) === 'number' ? v.toString() : typeOf(v) !== 'string' ? '' : v
  const current = value.replace(':', '')
  if (value.length >= 2) {
    return `${current.substring(0, 2)}:${current.substring(2)}`
  }
  return current
}
