<template>
  <div>
    <el-input
      :id="id"
      ref="input"
      v-model="localValue"
      v-mask="timeFormatMask"
      :data-testid="id"
      class="time-input"
      :class="{
        'validation-error': !disabled && errorMessage
      }"
      :placeholder="placeholder"
      :disabled="disabled"
      @focus="onFocus"
      @blur="onBlur"
    />
    <div
      class="absolute top-auto text-th-red text-xs"
      ng-if="!disabled && errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask'
import { timeRegex } from '@/utils/date'
import { formatToTime } from './helpers'

export default {
  directives: {
    mask: VueMaskDirective
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideWarningIcon: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ''
    },
    /**
     * A validation function from the parent that returns a boolean,
     */
    validateFn: {
      type: Function,
      default: undefined
    },
    // A message that will be shown if validateFn returns false
    validateFnErrorMessage: {
      type: String,
      default: ''
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timeFormatMask: '##:##',
      previousValue: '',
      maskConfig: {
        time: true,
        timePattern: ['h', 'm']
      },
      errorMessage: null
    }
  },
  computed: {
    timeNumberString() {
      const currentValue = (this.modelValue || '').replace(':', '')
      if (Number.isFinite(Number(currentValue))) {
        return currentValue
      }
      return ''
    },
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        if (modelValue.length > 5) return
        if (this.validateFormat(modelValue)) {
          this.customValidation(modelValue)
          this.$emit('update:modelValue', modelValue)
        }
      }
    }
  },
  watch: {
    initialValue: 'setInitialValue'
  },
  mounted() {
    this.setInitialValue()
  },
  methods: {
    onFocus() {
      this.$refs.input.select()
    },
    onBlur() {
      // if (this.errorMessage) return // we're already warning, so no need to override the error message
      if (this.allowEmpty && !this.timeNumberString.length) return // value is empty and empty is allowed - don't validate

      // Auto complete time
      let fixed = this.localValue.replace(':', '')
      if (fixed < 10) fixed = '0' + fixed
      fixed = fixed.padEnd(4, '0')
      this.localValue = formatToTime(fixed)
    },
    validateFormat(val) {
      if (val.length === 0) {
        // value is empty - no format to validate
        this.errorMessage = null
        return true
      }

      if (val < 10) val = '0' + val
      const current = val.replace(':', '').padEnd(4, '0') // always have 4 numbers to validate format against
      const formattedValue = formatToTime(current)

      const isValid = timeRegex().test(formattedValue)
      if (isValid === false) {
        this.errorMessage = this.$t('components.time_input.valid_format')
        return false
      }
      this.errorMessage = null
      return true
    },
    setInitialValue() {
      if (this.initialValue) {
        this.localValue = this.initialValue
      }
    },
    customValidation(val) {
      if (!this.validateFn) return

      if (val < 10) val = '0' + val
      const current = val.replace(':', '').padEnd(4, '0') // always have 4 numbers to validate format against
      const formattedValue = formatToTime(current)

      if (this.validateFn(formattedValue)) return

      if (!this.validateFnErrorMessage) {
        this.$log.error(
          new TypeError(
            'validateFnErrorMessage prop was not passed to th-time-input'
          )
        )
      } else {
        this.errorMessage = this.validateFnErrorMessage
      }
    }
  }
}
</script>

<style scoped>
.validation-error :deep(.el-input__inner) {
  border-color: var(--error-color-strong);
}

.time-input :deep(.el-input__inner) {
  text-align: center;
  padding: 0;
}
</style>
